<template>
	<div class="order-list">
		
		<div class="bind-item">
			<h1>域名</h1>
			<el-form ref="form" label-width="80px" style="margin-right: 1rem;">
				<el-form-item label="独立域名">
					<el-input v-model="configMap.saasDomain" placeholder="宝塔中为服务配置的域名"></el-input>
				</el-form-item>
				<div  class="bind-sure" >
					<el-button type="primary" @click="setConfig('saasDomain', configMap.saasDomain, '独立域名')">设置</el-button>
				</div>
			</el-form>
		</div>
		
		<div class="bind-item">
			<h1>SAAS-key <span v-if="configMap.saasAuth && configMap.saasAuth.expireDateShow" style="font-size: 0.8rem">
				(到期时间: {{ configMap.saasAuth.expireDateShow ? configMap.saasAuth.expireDateShow : '' }})</span>
			</h1>
			<el-form ref="form" label-width="80px" style="margin-right: 1rem;">
				<el-form-item label="saas-key">
					<el-input v-model="configMap.saasKey" placeholder="SAAS-KEY, 购买软件获得, 没有客户端无法运行"></el-input>
				</el-form-item>
				<div  class="bind-sure" >
					<el-button type="primary" @click="setSaasKey('saasKey', configMap.saasKey, 'saasKey')">设置</el-button>
				</div>
			</el-form>
		</div>
		
		
	
	</div>
</template>

<script>

export default {
	data() {
		return {
			
			configList:{},
			
			configMap:{
				
				saasDomain:'',
				saasKey:'',
				saasAuth: {
					expireDateShow: '',
				}
			},
		};
	},
	
	watch: {
	},
	
	mounted() {
		this.getConfig().then(res => {
			Object.keys(this.configMap).forEach((key) => {
				this[key] = this.configMap[key].value
			})
		})
	},
	
	methods: {
		getConfig() {
			return this.$http.post('/saas/saas/getConfig', {}).then(res => {
				if (res.code === 0) {
					this.configList = res.data.list ? res.data.list : []
					Object.keys(res.data.map).forEach((key) => {
						this.configMap[key] = res.data.map[key]
					})
				}
			})
		},
		
		setConfig(key, value, name) {
			if (!key || !value || !name) {
				this.$message.error('输入错误');
				return;
			}
			return this.$http.post('/saas/saas/setConfig', {
				key: key,
				value: value,
				name: name
			}).then(res => {
				if (res.code === 0) {
					this.$message({
						message: "设置"+name+"成功",
						type: "success",
					});
				}
			})
		},
		
		setSaasKey(key, value, name) {
			if (!key || !value || !name) {
				this.$message.error('输入错误');
				return;
			}
			return this.$http.post('/saas/saas/setConfig', {
				key: key,
				value: value,
				name: name
			}).then(res => {
				this.$message({
					message: "激活成功",
					type: "success",
				});
				window.location.reload()
			}).catch(err => {
				this.configMap.saasKey = ''
			})
		},
	},
};
</script>
<style lang="scss" scoped>
.bind-item {
	margin: 2rem 1rem;
	padding:1rem;
	border: solid 1px #eee;
	border-radius:1rem;
	h1 {
		margin: 1rem;
		text-align: center;
	}
	.bind-sure {
		display: flex;
		justify-content: center;
	}
	::v-deep .el-textarea__inner {
		height: 5rem !important;
	}
}
::v-deep .el-form-item__label {
	padding-right: 15px !important;
}
.logoUpload {
	display: flex;
	justify-content: center;
}
.logoImg {
	display: flex;
	justify-content: center;
	margin:1rem auto 2rem auto;
	border: solid 1px #eee;
	border-radius: 50%;
	max-width: 100px;
	text-align: center;
	img {
		max-width: 100%;
	}
}
.logoImgBanner {
	max-width: 100px;
}
.banner-upload {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin:1rem auto 2rem auto;
	border-radius: 50%;
	max-width: 100px;
	text-align: center;
	button {
		height: 2rem;
	}
	img {
		max-width: 100%;
	}
	.banner-button {
		display: flex;
		justify-content: center;
		align-content: center;
	}
	h3 {
		margin-bottom: 2rem;
	}
}
</style>
